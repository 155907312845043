/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useMemo } from 'react';
import PlanDetailsInput from './PlanDetailsInput';
import { getPlanPricingAPI } from '../request/plans';
import {
  PlanDetailsInputPropsPlanDetailsType,
  PlansPricingAPIResponseType,
} from '../types';
import useQuery from '../../../hooks/useQuery';
import { currentSelectedPlanCurrencyCodeAtom, planPricingAtom } from '../state';
import Loader from '../../../components/loader';
import { useAtomValue, useSetAtom } from 'jotai';

const tabs = [
  { label: 'MONTHLY', index: 0 },
  { label: 'QUATERLY', index: 1 },
  { label: 'YEARLY', index: 2 },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const planPricingState = useAtomValue(planPricingAtom);
  const setPlanCurrencyCode = useSetAtom(currentSelectedPlanCurrencyCodeAtom);

  const planDetails = useMemo(() => {
    let period = 'month';
    if (value === 1) {
      period = 'quarter';
    } else if (value === 2) {
      period = 'year';
    }
    return (planPricingState?.data?.plans ?? []).map((plan) => {
      setPlanCurrencyCode({
        currencyCode: plan?.currencyCode ?? 'INR',
      });
      const planItem = (plan?.price ?? []).find((price) => {
        if (period === 'quarter') {
          return price.unit === 'month' && price.period === 3;
        } else if (period === 'month') {
          return price.unit === 'month' && price.period === 1;
        } else {
          return price.unit === 'year';
        }
      });
      return {
        displayName: plan.displayName,
        numUsers: plan.numUsers,
        ...planItem,
      };
    });
  }, [value, planPricingState, setPlanCurrencyCode]);

  const renderTabBody = () => {
    // period = month, quarter, year
    // output = starter, pro, enterprise
    return (
      <PlanDetailsInput
        key={value}
        planDetails={planDetails as PlanDetailsInputPropsPlanDetailsType}
      />
    );
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      css={css`
        margin-top: 20px;
      `}
    >
      {renderTabBody()}
    </div>
  );
}

const PlanTabs: React.FC<{}> = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    requestData: { loading },
  } = useQuery<PlansPricingAPIResponseType>({
    requestAtom: planPricingAtom,
    queryFunction: getPlanPricingAPI,
    cacheData: false,
  });

  if (loading) {
    return (
      <Box mt={'100px'} width={'600px'}>
        <Loader size={32} secondary={'Loading...'} />
      </Box>
    );
  }

  return (
    <div>
      <Tabs
        value={value}
        css={css`
          margin-top: 20px;
          border-bottom: 1px solid #e0e0e9;
        `}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{ style: { backgroundColor: '#3F906D' } }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            {...a11yProps(tab.index)}
            sx={{ '&.Mui-selected': { color: '#3F906D' } }}
          />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <CustomTabPanel key={tab.index} value={value} index={tab.index} />
      ))}
    </div>
  );
};

export default PlanTabs;
