/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  css,
} from '@mui/material';
import ProfileCell from '../../organisations/components/ProfileCell';
import StatusBadge from '../../../components/badge';
import {
  PaymentHistoryAPIResponsePaginationArgs,
  PaymentHistoryRowType,
} from '../types';
import { exportPaymentHistoryExcel, getPaymentHistory } from '../request';
import usePaginatedQuery, {
  UsePaginatedQueryResult,
} from '../../../hooks/usePaginatedQuery';
import { paymentHistoryAtom, walletConfigAtom } from '../state';
import { getDescriptionFromAction } from '../../../utils';
import Loader from '../../../components/loader';
import { useAtomValue } from 'jotai';
import { TableVirtuoso } from 'react-virtuoso';
import ExportIcon from '../../../../icons/ExportIcon';
import { openPopup } from '../../../utils/popup';
import useNotifications from '../../../hooks/useNotifications';
import { NotificationType } from '../../../types/notification';
import { formatCurrency } from '../../../utils/currencyFormatter';

const TableComponents = {
  Scroller: forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props: any) => (
    <Table {...props} style={{ borderCollapse: 'separate' }} />
  ),
  TableHead: TableHead as any,
  TableRow: TableRow as any,
  TableBody: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableFooter
      {...props}
      ref={ref}
      style={{
        position: 'unset',
      }}
    />
  )),
};

const PaymentHistory = () => {
  const walletConfig = useAtomValue(walletConfigAtom);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const { addNotification } = useNotifications();

  const getter = useCallback(
    async (paginationData?: PaymentHistoryAPIResponsePaginationArgs) => {
      // Update logs on walletConfig.data?.balance change
      if (walletConfig.data?.balance) {
        // Perform any necessary actions here
      }

      const response = await getPaymentHistory(
        paginationData?.nextLogDateCreated
      );
      return {
        data: response.data,
        paginationData: {
          nextLogDateCreated: response?.paginationOptions?.nextLogDateCreated,
          hasMore: response?.paginationOptions?.hasMoreLogs, // Add any required pagination options here
        },
      } as UsePaginatedQueryResult<
        PaymentHistoryRowType,
        PaymentHistoryAPIResponsePaginationArgs
      >;
    },
    [walletConfig.data?.balance]
  );

  const {
    requestData: { loading, error, data, isFetchingMore },
    fetchMore: fetchMoreCustomerSegments,
  } = usePaginatedQuery<
    PaymentHistoryRowType,
    PaymentHistoryAPIResponsePaginationArgs
  >({
    requestAtom: paymentHistoryAtom,
    getter: getter,
    cacheData: false,
  });

  const handleExportExcel = async () => {
    if (exportExcelLoading || !data || data.length === 0 || loading || error) {
      return;
    }
    setExportExcelLoading(true);
    try {
      const { filePath } = await exportPaymentHistoryExcel();
      openPopup(filePath);
    } catch (error) {
      addNotification({
        type: NotificationType.ERROR,
        message:
          (error as Error).message ||
          'Something went wrong, please refresh the page and retry',
      });
    } finally {
      setExportExcelLoading(false);
    }
  };

  const getDataRowMap = useCallback(
    (index: number, row: PaymentHistoryRowType) => {
      return (
        <>
          <TableCell width="150px">
            <ProfileCell
              maxLetters={25}
              name={row.name}
              isInitialsAvatar={true}
            />
          </TableCell>
          <TableCell width="200px">
            <Typography>{getDescriptionFromAction(row.action)}</Typography>
          </TableCell>
          <TableCell width="100px">
            <StatusBadge type={row.transactionType} />
          </TableCell>
          <TableCell width="100px">
            {formatCurrency(row.amount ?? 0, walletConfig.data?.currencyCode)}
          </TableCell>
          <TableCell width="180px">
            {new Date(row.dateCreated).toLocaleString()}
          </TableCell>
          <TableCell width="100px">
            <StatusBadge type={row.status} />
          </TableCell>
          <TableCell width="50px">{/* <MoreVertIcon /> */}</TableCell>
        </>
      );
    },
    [walletConfig.data?.currencyCode]
  );

  if (error) {
    return null;
  }

  const renderTableRows = () => {
    if (loading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    if (error) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  margin: auto;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Typography
                  css={css`
                    color: rgba(0, 0, 0, 0.87);
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  Some error occurred. Please try again later
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={7}>
            <Box
              css={css`
                height: 300px;
                margin: auto;
              `}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Typography
                css={css`
                  color: rgba(0, 0, 0, 0.87);
                  font-size: 14px;
                  font-weight: 400;
                `}
              >
                No transactions made till now
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderHeader = () => {
    return (
      <TableRow
        css={css`
          background-color: #fff;
        `}
      >
        <TableCell width="180px">Org name</TableCell>
        <TableCell width="200px">Description</TableCell>
        <TableCell width="100px">Type</TableCell>
        <TableCell width="100px">Amount</TableCell>
        <TableCell width="180px">Initiated on</TableCell>
        <TableCell width="100px">Status</TableCell>
        <TableCell width="50px"></TableCell>
      </TableRow>
    );
  };
  const renderLoaderInFooter = () => {
    if (isFetchingMore) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <Box
              css={css`
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Loader />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  if (!loading && !error && Array.isArray(data) && data.length > 0) {
    return (
      <Box my={'24px'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginBottom={'16px'}
        >
          <Typography
            css={{
              color: '#2d2d2d',
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Payment History
          </Typography>
          {false && (
            <Button
              onClick={handleExportExcel}
              css={css`
                border-radius: 4px;
                border-color: #3f906d;
              `}
              variant="outlined"
              startIcon={<ExportIcon />}
              disabled={
                exportExcelLoading ||
                !data ||
                data?.length === 0 ||
                loading ||
                !!error
              }
            >
              {exportExcelLoading ? (
                <Loader size={24} />
              ) : (
                <Typography
                  css={{
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Export
                </Typography>
              )}
            </Button>
          )}
        </Box>
        <TableVirtuoso
          style={{
            height: `400px`,
            width: '100%',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#fff',
          }}
          css={css`
            &::-webkit-scrollbar {
              display: none; /* Hide scrollbar for WebKit browsers */
            }
            -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
            scrollbar-width: none; /* Hide scrollbar for Firefox */
          `}
          data={data}
          fixedHeaderContent={renderHeader}
          totalCount={(data ?? []).length}
          itemContent={getDataRowMap}
          endReached={fetchMoreCustomerSegments}
          components={{
            ...TableComponents,
            EmptyPlaceholder: () => <>...</>,
          }}
          fixedFooterContent={renderLoaderInFooter}
        />
      </Box>
    );
  }

  return (
    <Box my={'24px'}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={'16px'}
      >
        <Typography
          css={{
            color: '#2d2d2d',
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          Payment History
        </Typography>
        {false && (
          <Button
            onClick={handleExportExcel}
            css={css`
              border-radius: 4px;
              border-color: #3f906d;
            `}
            variant="outlined"
            startIcon={<ExportIcon />}
            disabled={
              exportExcelLoading ||
              !data ||
              data?.length === 0 ||
              loading ||
              !!error
            }
          >
            {exportExcelLoading ? (
              <Loader size={24} />
            ) : (
              <Typography
                css={{
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Export
              </Typography>
            )}
          </Button>
        )}
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          maxHeight: 440,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 650, width: '100%' }}
          aria-label="wallet table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Org name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Initiated on</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {renderTableRows()}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentHistory;
