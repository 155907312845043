/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OrgListCustomersProps } from '../../home/types';
import ConfirmationModal from '../../../components/confirmation-modal';

const DeleteOrgConfirmationModal = ({
  open,
  onCancelClick,
  onOkClick,
  selectedOrg,
}: {
  open: boolean;
  onCancelClick: () => void;
  onOkClick: () => Promise<void>;
  selectedOrg: OrgListCustomersProps | null;
}) => {
  return (
    <ConfirmationModal
      open={open}
      isOkButtonDisabled={false}
      modalContainerStyles={css`
        padding: 0px;
        width: 500px;
      `}
      modalHeaderContainerStyles={css`
        padding: 12px 20px 12px 20px;
        border-bottom: 1px solid #e0e0e9;
      `}
      modalHeaderStyles={css`
        padding: 0px;
        font-size: 16px;
        font-weight: 600;
        color: #2d2d2d;
      `}
      headerTitle="Delete Organisation"
      okButtonText="Yes, Delete"
      buttonBg="#dd5e56"
      okButtonStyles={css`
          color: white;
        }
        `}
      modalActionsContainerStyles={css`
        padding: 12px 20px 12px 20px;
        border-top: 1px solid #e0e0e9;
      `}
      onClose={onCancelClick}
      onClick={onOkClick}
    >
      <Box
        css={css`
          padding: 20px;
          display: flex;
          flex-direction: column;
          row-gap: 12px;
        `}
      >
        <Typography
          css={css`
            font-size: 16px;
            font-weight: 600;
            color: #2d2d2d;
          `}
        >
          Are you sure you want to delete {selectedOrg?.name || 'this'} org?
        </Typography>
        <Typography
          css={css`
            font-size: 14px;
            font-weight: 400;
            color: #7a7a7a;
          `}
        >
          You are about to delete all the data related to{' '}
          {selectedOrg?.name || 'this'} org. This action cannot be undone. Do
          you wish to proceed?
        </Typography>
      </Box>
    </ConfirmationModal>
  );
};

export default DeleteOrgConfirmationModal;
