import { deleteReq } from '../../../infra/rest';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';

export const deleteOrgApi = async ({
  orgId,
}: {
  orgId: string;
}): Promise<void> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/customer/${orgId}`;
  return deleteReq<void>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};
